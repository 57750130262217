<template>
  <div>
    <Datatable ref="datatable" route="/client/liste" :fields="fields" :data_filters="filters" titre="Liste des prospects" :sort="sort">      
      <template #action>
        <b-button v-if="$api.acl(19)" variant="light-primary" class="mr-2 btn-icon" size="md" @click="$refs.modalDistribution.show()">
          <i class="fas fa-user text-white fa-size"></i>
        </b-button>                
        <b-button v-if="$api.acl(6)" variant="light-success" class="btn-icon" v-b-tooltip.hover title="Exporter votre rechercher" @click="toCSV()">
          <i class="fa fa-file-excel fa-size"></i>
        </b-button>
        <b-button variant="light-info" class="btn-icon ml-2" v-b-tooltip.hover title="Imprimer votre rechercher" @click="print()">
          <i class="fa fa-print fa-size"></i>
        </b-button>
        <b-dropdown v-if="$api.acl(7)" variant="light-danger" right v-b-tooltip.hover title="Envoyer un mail" no-caret class="ml-2" toggle-class="btn-icon" size="md">
          <template #button-content>
            <i class="fas" :class="loadMail ? 'fa-spin fa-spinner fa-size' : 'fa-envelope fa-size'"></i>
          </template>
          <b-dropdown-item v-for="(m,i) in mails" :key="i"  @click="tplMail = m.id; $refs.mailTpl.show()">{{m.titre}}</b-dropdown-item>
        </b-dropdown>
        <b-button v-if="$api.acl(8)" v-b-tooltip.hover size="md" title="Creer un nouveau prospect" variant="success" class="btn-icon ml-2" href="/#/prospect/nouveau"><i class="fas fa-plus"></i></b-button>        
      </template>      
      <template #cell(id)="data">
        <router-link :to="'/prospect/detail/'+data.value">{{data.value}}</router-link>
      </template>
      <template #cell(nom)="data">
        <router-link :to="'/prospect/detail/'+data.item.id">{{ data.value }} {{ data.item.prenom }}</router-link>
      </template>
      <template #cell(action)="data">
        <b-form-checkbox v-model="selected" :key="data.item.id" :value="data.item.id" size="lg" inline >
          <i class="fa fa-star text-warning" v-if="checkSelect(data.item.id)"></i>
        </b-form-checkbox>              
      </template>              
      <template #cell(telephone_1)="data">
        <Telephone v-model="data.item.telephone_1" :edit="false" />
      </template>
      <template #cell(email)="data">
        <a :href="`mailto:${data.value}`">{{ data.value }}</a>
      </template>      
      <template #cell(regime_id)="data">
        <span>{{ $api.getParam('Regime', data.value).titre }}</span>
      </template>
      <template #cell(user_id)="data">
        <span>{{ $api.getParam('User', data.value).titre }}</span>
      </template>
      <template #cell(source_id)="data">
        <span>{{ $api.getParam('Source', data.value).titre }}</span>
      </template>      
      <template #cell(date_effet)="data">
        {{ $api.moment(data.value).format("DD/MM/YYYY") }}
      </template>      
      <template #cell(date_create)="data">
        {{$api.timestampToDate(data.value)}}
      </template>      
      <template #cell(date_update)="data">
        {{$api.timestampToDate(data.value)}}
      </template>      
      <template #cell(status_id)="data">
        <b-dropdown v-b-tooltip.hover title="Changer le statut du prospect" no-caret  :variant="$api.p('ClientStatus', data.value).color" size="md">
          <template #button-content>
            {{ $api.p('ClientStatus', data.value).titre }} <i class="ml-2 fas fa-chevron-down"></i>
          </template>
          <b-dropdown-item v-for="(s,i) in $api.params.ClientStatus" :key="i" :disabled="$api.params.StatusLock.prospect_lock.includes(data.value)" :active="data.value === s.id" @click="changeStatus(data.item, s.id)">{{s.titre}}</b-dropdown-item>
        </b-dropdown>        
      </template>
    </Datatable>
    <b-modal id="mailTpl" ref="mailTpl" hide-footer title="Envoi de mail en masse" size="lg" centered>
      <b-card-text v-if="tplMail != null">Vous etes sur le point d'envoyer le mail <strong>"{{ $api.p('Template', tplMail).titre }}"</strong> à <strong>{{ $refs.datatable.datatable.totalRow }}</strong> contacts</b-card-text>
      <div class="d-block text-center mt-3">
        <b-button variant="light-primary" :class="loadMail ? 'btn-icon' : ''" @click="sendMails()">
          <span v-if="loadMail === false" class="text-white">Envoyer</span>
          <i v-else class="fa fa-spin fa-spinner"></i>
        </b-button>
      </div>
    </b-modal>
    <b-modal id="modalDistribution" ref="modalDistribution" hide-footer title="Envoi des prospects" size="lg" centered>
      <b-card-text>Vous etes sur le point d'envoyer <strong>{{ selected.length }} prospects</strong> </b-card-text>
      <b-form-group label="Commercial:">
        <Input type="select-model" :options="$api.params.User" v-model="user_id"/>
      </b-form-group>
      <div class="d-block text-center mt-3">
        <b-button variant="light-primary" :class="loadDistribution ? 'btn-icon' : ''" @click="sendLeads()">
          <span v-if="loadDistribution === false" class="text-white">Envoyer</span>
          <i v-else class="fa fa-spin fa-spinner"></i>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Datatable from '@/components/Datatable';
import Telephone from '@/components/Telephone';
import Input from '@/components/InputForm';
export default {
  name: "ClientListe",
  components: {
    Datatable,Telephone, Input
  },
  watch:{    
  },
  data(){ 
    var fields = [{key: 'id', label: 'Ref', sortable:true,}]
    if(this.$api.acl(19)){
      fields.push({ key: 'action', label: 'Action', sortable:false, });
    }
    fields = fields.concat([ 
      { key: 'nom', label: 'Nom', sortable:true, }, 
      { key: 'telephone_1', label: 'Telephone 1', sortable:false, }, 
      { key: 'email', label: 'Email', sortable:false, }, 
      { key: 'date_effet', label: 'Date effet', sortable:false, }, 
      { key: 'user_id', label: 'Commercial', sortable:true, }, 
      { key: 'source_id', label: 'Source', sortable:true, }, 
      { key: 'date_create', label: 'Creation', sortable:true, }, 
      { key: 'date_update', label: 'Modification', sortable:false, }, 
      { key: 'status_id', label: 'Statut', sortable:true, }, 
    ]);
    return{
      filters:{},
      fields: fields,      
      sort:{
        key: 'date_update',
        value: 'DESC'
      },
      loadMail:false,
      tplMail: null,
      selected:[],
      loadDistribution: false,
      user_id: null,      
    }
  },
  computed:{
    mails(){
      var type = "ClientMail";
      return this.$api.params.Template.filter(t => t.type === type);
    }    
  },
  methods: {
    changeStatus(data, status){
      data.status_id = status;
      var params = {
        id: data.id,
        status_id: status
      }
      this.$api.ajax('/client/create', params, res => {
        if(res.status === true){
          this.$bvToast.toast('Sauvegarder', {
            title: 'Sauvegarder',
            variant: 'success',
            solid: true
          });          
        }
      })
    },
    sendMails(){
      if(this.loadMail === true){
        return true;
      }
      this.loadMail = true;
      var params = {
        tpl_id: this.tplMail,
        params: this.$refs.datatable.params
      }
      this.$api.ajax('/client/mails', params, res => {
        if(res.status === true){
          this.$refs.mailTpl.hide();
          this.$bvToast.toast("L'envoi c'est correctement fini", {
            title: 'Sauvegarder',
            variant: 'success',
            solid: true
          });
        }
        this.loadMail = false;
      });
    },
    print(){
      window.print();
    },
    toCSV(){
      var rows = [];
      var title = ['ID','Nom', 'Telephone 1', 'Email', 'Date effet', 'Commercial', 'Source', 'Dae creation', 'Statut'];
      rows.push(title.join(';'));
      this.$refs.datatable.datatable.data.forEach((r) => {
        var tmp = [];
        tmp.push(r.id);
        tmp.push(r.nom);
        tmp.push(r.telephone_1);
        tmp.push(r.email);
        tmp.push(r.date_effet);
        tmp.push(this.$api.p('User', r.user_id).titre);          
        tmp.push(this.$api.p('Source', r.source_id).titre);          
        tmp.push(this.$api.moment(r.date_create * 1000).format("DD/MM/YYYY HH:mm:ss"));
        tmp.push(this.$api.p('ClientStatus', r.status_id).titre);          
        rows.push(tmp.join(';'));        
      });
      rows = rows.join("\n");
      var hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(rows);
      hiddenElement.target = '_blank';
      hiddenElement.download = 'Export.csv';
      hiddenElement.click();
    },
    checkSelect(lead_id){
      return this.selected.filter(s => s === lead_id).length === 1;
    },
    sendLeads(){
      if(this.loadDistribution === true){
        return false;
      }
      this.loadDistribution = true;
      var params = {
        user_id:this.user_id,
        leads: this.selected
      }
      this.$api.ajax('/client/distribution', params, res => {
        if(res.status === true){          
          this.$bvToast.toast("Les propects on bien ete distribue", {
            title: 'Sauvegarder',
            variant: 'success',
            solid: true
          });
          this.selected = [];
          this.user_id = null;
          this.$refs.datatable.datatableInit();
          this.$refs.modalDistribution.hide();
        }
        this.loadDistribution = false;
      })
    }
  },
  beforeMount() {
  },
  mounted() {        
  }
}
</script>
<style>
@media print {
  .menu-desktop, #menu-mobile, #header{
    display: none !important;
  }            
}
</style>
